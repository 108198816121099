import createClient from "openapi-fetch";
import { paths as configurationPaths } from "../types/configuration";
import { paths as patronPaths } from "../types/patron";
import { paths as searchPaths } from "../types/search";
import { paths as libraryPaths } from "../types/library";
import { createSessionMiddleware } from "./sessionMiddleware";
import {
  Session,
  PatronReservation,
  RediaPlatformProps,
  RediaPlatform,
  Publication,
  SessionConfiguration,
} from "./interfaces";
import { KeyValueStore, createSessionStore } from "./sessionStore";
import {
  LoginFailed,
  RediaPlatformConfigurationApiError,
  RediaPlatformError,
  RediaPlatformPatronApiError,
} from "./errors";

const sessionStorageKey = "libry-content-session";

export const createRediaPlatformClient = (props: RediaPlatformProps): RediaPlatform => new RediaPlatformClient(props);

class RediaPlatformClient implements RediaPlatform {
  private apis: {
    configuration: ReturnType<typeof createClient<configurationPaths>>;
    patron: ReturnType<typeof createClient<patronPaths>>;
    search: ReturnType<typeof createClient<searchPaths>>;
    library: ReturnType<typeof createClient<libraryPaths>>;
  };
  private sessionStore: KeyValueStore<Session>;
  private clientId: string;
  public readonly customerId: string;
  public readonly environment: RediaPlatformProps["environment"];

  constructor(props: RediaPlatformProps) {
    const { clientId, customerId, environment, onSessionChange, onSessionExpired } = props;
    const environmentSuffix = environment === "prod" ? "" : `-${environment}`;

    this.environment = environment;
    this.clientId = clientId;
    this.customerId = customerId;

    this.sessionStore = createSessionStore<Session>({ key: sessionStorageKey, onChange: onSessionChange });

    const sessionMiddleware = createSessionMiddleware(this.sessionStore, { onSessionExpired });
    const fetchWithSession = sessionMiddleware(fetch);

    this.apis = {
      configuration: createClient<configurationPaths>({
        baseUrl: `https://configuration${environmentSuffix}.redia.dk/`,
        fetch: fetchWithSession,
      }),
      patron: createClient<patronPaths>({
        baseUrl: `https://patron${environmentSuffix}.redia.dk/`,
        fetch: fetchWithSession,
      }),
      search: createClient<searchPaths>({
        baseUrl: `https://search${environmentSuffix}.redia.dk/`,
        fetch: fetchWithSession,
      }),
      library: createClient<libraryPaths>({
        baseUrl: `https://library${environmentSuffix}.redia.dk/`,
        fetch: fetchWithSession,
      }),
    };
  }

  private async createNewSession() {
    return this.apis.configuration.POST("/session/token", {
      body: {
        customerCode: this.customerId,
        secret: this.clientId, // "Ikke egentlig en secret" iflg. Redia. Vi kaller det clientId.
        productCode: "content",
        version: "v1.99.99",
        platform: "web",
      },
    });
  }

  public async login(username: string, password: string) {
    // Start alltid en fersk sesjon før innlogging, slik at sesjonslengden
    // samsvarer med hvor lenge en har vært logget inn.
    const { data: session, error: sessionError } = await this.createNewSession();
    if (sessionError) {
      console.error("Failed to create session", sessionError);
      throw new LoginFailed(new RediaPlatformConfigurationApiError(sessionError));
    }

    this.sessionStore.set({
      token: session.token,
    });

    const { data, error: loginError } = await this.apis.patron.POST("/api/patron/authentication", {
      body: {
        cardIdentifier: username,
        password: password,
      },
    });
    if (loginError) {
      // Ingen vits å spare på sesjonen hvis vi ikke fikk logget inn.
      this.sessionStore.clear();
      throw new LoginFailed(new RediaPlatformPatronApiError(loginError));
    }
    this.sessionStore.set({
      token: session.token,
      user: data.patron,
    });
  }

  public async logout() {
    const { error } = await this.apis.patron.GET("/api/patron/deauthenticate", {});
    if (error) {
      console.error("Failed to logout user from Redia Platform", error);
      // Her kan vi evt. la være å tømme den lokale sesjonen og la brukeren
      // prøve på nytt. Men risikoen for at det feiler på nytt er vel stor, og
      // da bli en sittende med en lokal sesjon uten mulighet til å logge ut. Så
      // kanskje tross alt beste å slette den lokale sesjonen. Sesjonen hos
      // Redia vil jo uansett ikke vare mer enn 30 minutter.
    }
    this.sessionStore.clear();
  }

  public async getConfiguration(): Promise<SessionConfiguration> {
    // Create temporary session
    const { data: session, error: sessionError } = await this.createNewSession();
    if (sessionError) throw new RediaPlatformError(sessionError);
    const { data, error } = await this.apis.configuration.GET("/session/configuration", {
      headers: {
        Authorization: `Bearer ${session.token.token}`,
      },
    });
    if (error) throw new RediaPlatformError(error);
    return data.configuration;
  }

  public getUser() {
    const session = this.sessionStore.get();
    return session?.user;
  }

  public async refreshUserProfile() {
    const { data, error } = await this.apis.patron.GET("/api/patron/information", {});
    const session = this.sessionStore.get();
    if (data && session) {
      this.sessionStore.set({
        ...session,
        user: data.patron,
      });
    } else {
      console.error("Failed to refresh patron profile", error);
    }
  }

  public async getReservations(): Promise<PatronReservation[]> {
    const { data, error } = await this.apis.patron.GET("/api/patron/reservation", {});
    if (error) throw new RediaPlatformError(error);
    return data.reservations;
  }

  public async deleteReservation(reservationId: string) {
    const { error } = await this.apis.patron.DELETE("/api/patron/reservation/{reservationId}", {
      params: {
        path: { reservationId },
      },
    });
    if (error) throw new RediaPlatformError(error);
  }

  public async createReservation(reservationId: string) {
    throw new Error("Not implemented in Redia Platform yet (2023-08-22)");
  }

  public async getLoans() {
    const { data, error } = await this.apis.patron.GET("/api/patron/loan", {});
    if (error) throw new RediaPlatformError(error);
    return data.loans;
  }

  public async renewLoan(loanId: string) {
    const { data, error } = await this.apis.patron.PUT("/api/patron/loan/{loanId}", {
      params: {
        path: { loanId },
      },
    });
    if (error) throw new RediaPlatformError(error);
    return data.loan;
  }

  public async getLocations() {
    throw new Error("Not implemented in Redia Platform yet (2023-08-22)");
  }

  public async getPublications(publicationIds: string[]): Promise<Record<string, Publication | undefined>> {
    if (publicationIds.length === 0) return {};
    const { data, error } = await this.apis.search.GET("/api/publication", {
      params: {
        query: {
          publication_id: publicationIds,
        },
      },
      querySerializer(query) {
        return Object.entries(query)
          .flatMap(([key, value]) => {
            if (Array.isArray(value)) {
              return value.map((entry) => `${key}=${entry}`);
            }
            return [`${key}=${value}`];
          })
          .join("&");
      },
    });
    if (error) throw new RediaPlatformError(error);
    return data.publications;
  }
}

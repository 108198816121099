import {
  PatronReservation,
  RediaPlatform,
  Patron,
  PatronLoan,
  Session,
  SessionToken,
  Publication,
  Schemas,
} from "../client/interfaces";

const mockPatron: Patron = {
  patronId: "ix912",
  firstName: "Test",
  surname: "Testesen",
  email: "test@example.com",
  pickupBranchId: "tb",
  phoneNumbers: [],
  identificationCards: [
    {
      type: "no_local",
      identifier: "N12345678",
    },
  ],
};

const mockReservations: PatronReservation[] = [
  {
    id: "ix-123",
    publicationId: "123",
    createdTime: "2021-01-01T00:00:00.000Z",
    expireDate: "2021-01-01T00:00:00.000Z",
    pickupBranchId: "tb",
    pickupExpireDate: "2021-01-01T00:00:00.000Z",
    pickupState: "ready",
    pickupId: "123",
    queueNumber: 1,
    itemId: "123",
    interLibraryLoan: undefined,
  },
];

const mockLoans: PatronLoan[] = [];

const mockPublications: Record<string, Publication | undefined> = {};

export const createRediaPlatformMockClient = (): RediaPlatform => new RediaPlatformMockClient();

class RediaPlatformMockClient implements RediaPlatform {
  private onSessionChange: ((session: Session) => void) | undefined;
  private token: SessionToken;
  public environment = "test";
  public customerId = "testbib";

  public constructor({ onSessionChange }: { onSessionChange?: (session: Session) => void } = {}) {
    this.onSessionChange = onSessionChange;
    this.token = { type: "bearer", token: "21", expiresTime: "2021-01-01T00:00:00.000Z" };
    if (this.onSessionChange) this.onSessionChange({ token: this.token, user: undefined });
  }

  public async login(username: string, password: string) {
    if (this.onSessionChange) this.onSessionChange({ token: this.token, user: mockPatron });
  }

  public async logout() {
    if (this.onSessionChange) this.onSessionChange({ token: this.token, user: undefined });
  }

  public async getConfiguration() {
    const config: Schemas["configuration"]["ConfigurationContent"] = {
      productCode: "content",
      name: "Testbiblioteket",
    };
    return config;
  }

  public async getCustomerGroups() {
    return [];
  }

  public getUser() {
    return mockPatron;
  }

  public async refreshUserProfile() {
    // Do nothing
  }

  public async getReservations(): Promise<PatronReservation[]> {
    return mockReservations;
  }

  public async deleteReservation(reservationId: string) {
    // Do nothing
  }

  public async createReservation(reservationId: string) {
    // Do nothing
  }

  public async getLoans() {
    return mockLoans;
  }

  public async renewLoan(loanId: string) {
    const loan = mockLoans[0];
    if (!loan) {
      // Make TS happy
      throw new Error("Loan not found");
    }
    return loan;
  }

  public async getPublications(publicationIds: string[]) {
    return mockPublications;
  }
}

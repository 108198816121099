import { useEffect, useMemo, useState } from "react";
import { Patron, RediaPlatform, RediaPlatformProps, createRediaPlatformClient } from "@libry-content/redia-platform";
import { useClientSideReady } from "../utils/hooks/useClientSideReady";
import { useCommonData } from "../components/layout/CommonDataProvider";

export interface RediaPlatformContext {
  isRediaPlatformConfigured: boolean;
  isSessionReady: boolean;
  rediaPlatform: RediaPlatform | undefined;
  user: Patron | undefined;
}

const clientId = process.env.NEXT_PUBLIC_REDIA_PLATFORM_CLIENT_ID;
if (!clientId) {
  console.warn(`Redia Platform not enabled since NEXT_PUBLIC_REDIA_PLATFORM_CLIENT_ID is not set.`);
}

const rediaPlatformEnvironment: RediaPlatformProps["environment"] = "dev"; // isProduction ? "prod" : "dev"; // Redias prod-miljø er ikke deployet enda.

const localStorageSessionKey = "libry-content-session";

export const useRediaPlatform = () => {
  const customerId = useCommonData()?.site?.librarySystem?.rediaCustomerId;
  const clientSideReady = useClientSideReady();
  const [user, setUser] = useState<Patron | undefined>(undefined);
  const [isSessionReady, setIsSessionReady] = useState<boolean>(false);
  const isRediaPlatformConfigured = typeof clientId !== "undefined" && typeof customerId !== "undefined"; 

  const rediaPlatform = useMemo(() => {
    if (!clientSideReady || !isRediaPlatformConfigured) return undefined;
    return createRediaPlatformClient({
      environment: rediaPlatformEnvironment,
      customerId,
      clientId,
      onSessionChange: (session) => {
        // Oppdaterer React state når bruker har logget inn/ut
        console.debug("User state changed", session?.user);
        setUser(session?.user);
        setIsSessionReady(true);
      },
      onSessionExpired: () => {
        // Kanskje vi burde vise en form for toast her? Eller noe annet?
        // Vi trenger ikke kalle setUser her fordi onSessionChange også kalles.
      },
    });
  }, [clientSideReady, customerId, isRediaPlatformConfigured]);

  useEffect(() => {
    const onStorageEvent = (event: StorageEvent) => {
      if (event.key === localStorageSessionKey) {
        // Oppdaterer React state når bruker har logget inn/ut i annet vindu/annen fane.
        setUser(rediaPlatform?.getUser());
      }
    };
    window.addEventListener("storage", onStorageEvent);
    return () => {
      window.removeEventListener("storage", onStorageEvent);
    };
  }, [rediaPlatform]);

  return {
    isRediaPlatformConfigured,
    rediaPlatform,
    user,
    isSessionReady,
  };
};
